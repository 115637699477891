<template>
  <div id="Fontainebleau">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getFontainebleauMapData } from "@/data/sectionData/whereWeAreData/fontainebleauMapData"

export default {
  name: "Fontainebleau",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getFontainebleauMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>
<!-- 4.7miles for Fountainebleau -->
