export const getFontainebleauMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-fontainebleau-route.jpg'),
            mapLine: 'FontainebleauPath',
            venueBg:  require('@/assets/maps/fontainebleau.png'),
            venue: 'Fontainebleau',
            distance: '4.7',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
